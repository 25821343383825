// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddCartForm_wrapperComponent__uO9iP .AddCartForm_actions__B90AV {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-top: 20px;\n}\n.AddCartForm_wrapperComponent__uO9iP .AddCartForm_actions__B90AV button + button {\n  margin-left: 16px;\n}", "",{"version":3,"sources":["webpack://./src/theme/components/containers/AddCartForm/AddCartForm.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AAAJ;AAEI;EACE,iBAAA;AAAN","sourcesContent":[".wrapperComponent {\n  .actions {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    margin-top: 20px;\n\n    button + button {\n      margin-left: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperComponent": "AddCartForm_wrapperComponent__uO9iP",
	"actions": "AddCartForm_actions__B90AV"
};
export default ___CSS_LOADER_EXPORT___;
