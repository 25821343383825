// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SectionForm_inputs__roaTa {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: center;\n}\n.SectionForm_inputs__roaTa textarea {\n  width: 100%;\n}\n\n.SectionForm_actions__Rs694 {\n  display: flex;\n  justify-content: flex-end;\n}\n.SectionForm_actions__Rs694 button:nth-child(2) {\n  margin-left: 20px;\n}", "",{"version":3,"sources":["webpack://./src/theme/pages/Cms/components/SectionForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,8BAAA;EACA,mBAAA;AACF;AACE;EACE,WAAA;AACJ;;AAGA;EACE,aAAA;EACA,yBAAA;AAAF;AAEE;EACE,iBAAA;AAAJ","sourcesContent":[".inputs {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  align-items: center;\n\n  textarea {\n    width: 100%;\n  }\n}\n\n.actions {\n  display: flex;\n  justify-content: flex-end;\n\n  button:nth-child(2) {\n    margin-left: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputs": "SectionForm_inputs__roaTa",
	"actions": "SectionForm_actions__Rs694"
};
export default ___CSS_LOADER_EXPORT___;
