// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChangeStatus_wrapperComponent__deUFl {\n  text-align: center;\n}\n\n.ChangeStatus_actions__rleJ9 {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n.ChangeStatus_actions__rleJ9 button + button {\n  margin-left: 16px;\n}", "",{"version":3,"sources":["webpack://./src/theme/pages/Users/components/ChangeStatus/ChangeStatus.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AACF;AACE;EACE,iBAAA;AACJ","sourcesContent":[".wrapperComponent {\n  text-align: center;\n}\n\n.actions {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-top: 32px;\n\n  button + button {\n    margin-left: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperComponent": "ChangeStatus_wrapperComponent__deUFl",
	"actions": "ChangeStatus_actions__rleJ9"
};
export default ___CSS_LOADER_EXPORT___;
