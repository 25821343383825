// główna wyszukiwarka produktów

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ArrowLeft, Search as SearchIcon } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { reduxActions, useDispatch, useSelector } from 'store';
import { useAppNavigate, useRWD } from 'hooks';
import { useGetSearchCategories, useGetSearchProducts } from 'api';

import styles from 'theme/components/containers/Search/Search.module.scss';
import DesktopOverlay from './DektopOverlay';
import MobileOverlay from './MobileOverlay';

const Search = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useAppNavigate();
  const location = useLocation();

  const [showCategories, setShowCategories] = useState<boolean>(false);

  const { isMobile } = useRWD();

  // ref popover'a
  const wrapperRef = useRef<HTMLDivElement>(null);

  // czy popover jest widoczny (focus na inpucie)
  const [isFocus, setIsFocus] = useState(false);

  // fraza wyszukiwania
  const [searchKeyword, setSearchKeyword] = useState('');

  // parametry wyszukiwania
  const [queryParams, setQueryParams] = useState({ page: 1, limit: 5, search_keyword: '' });

  // zapisana globalna fraza wyszukiwana - domyślna wartość tutaj i parametr na stronie listy produktów
  const { searchKeyword: globalSearchKeyboard } = useSelector((state) => state.products);

  // pobranie listy produktów
  const {
    data: productsData,
    isLoading: isProductsDataLoading,
    refetch: refetchProductsData
  } = useGetSearchProducts(queryParams, { enabled: false });

  // pobranie listy kategorii
  const {
    data: categoriesData,
    isLoading: isCategoriesDataLoading,
    refetch: refetchCategoriesData
  } = useGetSearchCategories(
    {
      page: 1,
      limit: 999,
      search_keyword: queryParams.search_keyword
    },
    { enabled: false }
  );

  // funcja aktualizująca frazę wyszukiwania (sekunda opóźnienia dla optymalizacji ilości zapytań do api)
  const searchKeywordDebounce = useCallback(
    debounce(
      (phrase) => setQueryParams((prevState) => ({ ...prevState, search_keyword: phrase })),
      1000
    ),
    []
  );

  // nasłuchiwanie na zmiany frazy wyszukiwania w lokalnym inpucie
  useEffect(() => {
    searchKeywordDebounce(searchKeyword);
  }, [searchKeyword]);

  const setGlobalKeyword = () => {
    setSearchKeyword(globalSearchKeyboard);
  };

  // nasłuchiwanie na zmiany globalnej frazy wyszukiwania
  useEffect(() => {
    setGlobalKeyword();
  }, [globalSearchKeyboard]);

  // pobranie kategorii po zmianie frazy wyszukiwania
  useEffect(() => {
    if (queryParams.search_keyword) {
      refetchCategoriesData();
      refetchProductsData();
    }
  }, [queryParams]);

  // zamknięcie okienka po zmianie url'a
  useEffect(() => {
    dispatch(reduxActions.setSearchKeyword(searchKeyword));
    setIsFocus(false);
  }, [location]);

  // funkcja wywona po klinięciu w linki wewnątrz wyszukiwarki
  const applyProductsParameters = (categoryId?: number) => {
    dispatch(reduxActions.setSearchKeyword(searchKeyword));
    dispatch(reduxActions.setCategoryId(categoryId));
    setIsFocus(false);
    !window.location.pathname.endsWith('/products') &&
      navigate(
        `/products?${categoryId ? `category_id=${categoryId}&` : ''}search_keyword=${searchKeyword}`
      );
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        { [styles.focus]: isFocus },
        'StylePath-Components-Containers-Search'
      )}
      ref={wrapperRef}>
      <ArrowLeft
        className={classnames(styles.icon, { [styles.hide]: !(isFocus && isMobile) })}
        onClick={() => setIsFocus(false)}
      />
      <input
        placeholder={`${t('Szukaj')}...`}
        value={searchKeyword}
        onChange={(event) => setSearchKeyword(event.target.value)}
        onKeyUp={(event) => {
          setShowCategories(true);
          event.key === 'Enter' &&
            (applyProductsParameters(),
            event.target instanceof HTMLInputElement && event.target.blur());
        }}
        onClick={() => {
          setIsFocus(true);
          if (isMobile) {
            setShowCategories(true);
          }
        }}
        onFocus={() => {
          setIsFocus(true);
          if (isMobile) {
            setShowCategories(true);
          }
        }}
      />
      <div className={styles.icon} onClick={() => applyProductsParameters()}>
        <SearchIcon />
      </div>
      {isFocus &&
        (isMobile ? (
          <MobileOverlay
            categoriesData={categoriesData}
            isCategoriesDataLoading={isCategoriesDataLoading}
            setShowCategories={setShowCategories}
            showCategories={showCategories}
            productsData={productsData}
            isProductsDataLoading={isProductsDataLoading}
            searchKeyword={searchKeyword}
            setGlobalKeyword={setGlobalKeyword}
            isFocus={isFocus}
            setIsFocus={setIsFocus}
            applyProductsParameters={applyProductsParameters}
          />
        ) : (
          <DesktopOverlay
            categoriesData={categoriesData}
            isCategoriesDataLoading={isCategoriesDataLoading}
            productsData={productsData}
            isProductsDataLoading={isProductsDataLoading}
            searchKeyword={searchKeyword}
            setGlobalKeyword={setGlobalKeyword}
            setIsFocus={setIsFocus}
            applyProductsParameters={applyProductsParameters}
          />
        ))}
    </div>
  );
};

export default Search;
