// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResetPassword_wrapperComponent__JvB4P h2 {\n  margin-top: 20px;\n  text-align: center;\n}\n\n.ResetPassword_form__Z43m- {\n  margin: 20px auto;\n  width: 100%;\n  max-width: 300px;\n}\n.ResetPassword_form__Z43m- .ResetPassword_actions__nKPiz {\n  margin: 16px 0;\n  text-align: right;\n}", "",{"version":3,"sources":["webpack://./src/theme/pages/ResetPassword/ResetPassword.module.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,kBAAA;AAAJ;;AAIA;EACE,iBAAA;EACA,WAAA;EACA,gBAAA;AADF;AAGE;EACE,cAAA;EACA,iBAAA;AADJ","sourcesContent":[".wrapperComponent {\n  h2 {\n    margin-top: 20px;\n    text-align: center;\n  }\n};\n\n.form {\n  margin: 20px auto;\n  width: 100%;\n  max-width: 300px;\n\n  .actions {\n    margin: 16px 0;\n    text-align: right;\n  }\n};\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperComponent": "ResetPassword_wrapperComponent__JvB4P",
	"form": "ResetPassword_form__Z43m-",
	"actions": "ResetPassword_actions__nKPiz"
};
export default ___CSS_LOADER_EXPORT___;
