// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ThankYouRegister_wrapperComponent__eT3Ia {\n  padding: 30px 0;\n}\n.ThankYouRegister_wrapperComponent__eT3Ia h3 {\n  text-align: center;\n}\n.ThankYouRegister_wrapperComponent__eT3Ia .ThankYouRegister_actions__yowA- {\n  margin-top: 30px;\n  display: flex;\n  justify-content: center;\n}", "",{"version":3,"sources":["webpack://./src/theme/pages/ThankYouRegister/ThankYouRegister.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AACE;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;AACJ","sourcesContent":[".wrapperComponent {\n  padding: 30px 0;\n  h3 {\n    text-align: center;\n  }\n\n  .actions {\n    margin-top: 30px;\n    display: flex;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperComponent": "ThankYouRegister_wrapperComponent__eT3Ia",
	"actions": "ThankYouRegister_actions__yowA-"
};
export default ___CSS_LOADER_EXPORT___;
