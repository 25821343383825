// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomerForm_componentWrapper__zVXIB {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n\n.CustomerForm_formElement__2gXmY {\n  width: 100%;\n  margin-bottom: 12px;\n}\n.CustomerForm_formElement__2gXmY label {\n  font-weight: 700;\n}\n.CustomerForm_formElement__2gXmY.CustomerForm_halfWidth__EIvAq {\n  width: calc(50% - 10px);\n}\n\n.CustomerForm_actions__BJQQt {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n.CustomerForm_actions__BJQQt button + button {\n  margin-left: 16px;\n}", "",{"version":3,"sources":["webpack://./src/theme/pages/Checkout/components/CustomerForm/CustomerForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,uBAAA;AAAJ;;AAIA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AADF;AAGE;EACE,iBAAA;AADJ","sourcesContent":[".componentWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n\n.formElement {\n  width: 100%;\n  margin-bottom: 12px;\n\n  label {\n    font-weight: 700;\n  }\n\n  &.halfWidth {\n    width: calc(50% - 10px);\n  }\n}\n\n.actions {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-top: 32px;\n\n  button + button {\n    margin-left: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"componentWrapper": "CustomerForm_componentWrapper__zVXIB",
	"formElement": "CustomerForm_formElement__2gXmY",
	"halfWidth": "CustomerForm_halfWidth__EIvAq",
	"actions": "CustomerForm_actions__BJQQt"
};
export default ___CSS_LOADER_EXPORT___;
