// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RemoveExport_wrapperComponent__Yijrs {\n  display: block;\n}\n\n.RemoveExport_actions__yKtuV {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-top: 12px;\n}\n.RemoveExport_actions__yKtuV button + button {\n  margin-left: 16px;\n}", "",{"version":3,"sources":["webpack://./src/theme/pages/Export/components/RemoveExport/RemoveExport.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AACF;AACE;EACE,iBAAA;AACJ","sourcesContent":[".wrapperComponent {\n  display: block;\n}\n\n.actions {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-top: 12px;\n\n  button + button {\n    margin-left: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperComponent": "RemoveExport_wrapperComponent__Yijrs",
	"actions": "RemoveExport_actions__yKtuV"
};
export default ___CSS_LOADER_EXPORT___;
