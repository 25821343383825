// Globalny stan aplikacji - produkty
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// typ products
interface IProductsSlice {
  // główna fraza wyszukiwania
  searchKeyword: string;
  // wybrana kategoria
  categoryId?: number;
}

// dane inicjalizacyjne
const initialState: IProductsSlice = {
  searchKeyword: ''
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setSearchKeyword: (state, action: PayloadAction<IProductsSlice['searchKeyword']>) => {
      state.searchKeyword = action.payload;
    },
    setCategoryId: (state, action: PayloadAction<IProductsSlice['categoryId']>) => {
      state.categoryId = action.payload;
    }
  }
});

export const productsSliceActions = productsSlice.actions;

export default productsSlice.reducer;
