// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OffersPeriodic_wrapperComponent__yT74N {\n  display: block;\n}\n\n.OffersPeriodic_header__WESnz {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 16px 0;\n}\n@media (max-width: 900px) {\n  .OffersPeriodic_header__WESnz {\n    padding: 0 16px 16px;\n  }\n  .OffersPeriodic_header__WESnz > :last-child {\n    display: none;\n  }\n}\n\n.OffersPeriodic_searchFormWrapper__rhQSB {\n  width: 400px;\n}\n@media (max-width: 900px) {\n  .OffersPeriodic_searchFormWrapper__rhQSB {\n    width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://./src/theme/pages/OffersPeriodic/OffersPeriodic.module.scss","webpack://./src/theme/_breakpoints.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AADF;ACAI;EDHJ;IAWI,oBAAA;EAJF;EAAE;IACE,aAAA;EAEJ;AACF;;AAIA;EACE,YAAA;AADF;ACZI;EDYJ;IAGI,WAAA;EACF;AACF","sourcesContent":["@import 'src/theme/breakpoints';\n\n.wrapperComponent {\n  display: block;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 16px 0;\n\n  @include breakpoint(md) {\n    > :last-child {\n      display: none;\n    }\n\n    padding: 0 16px 16px;\n  }\n}\n\n.searchFormWrapper {\n  width: 400px;\n  @include breakpoint(md) {\n    width: 100%;\n  }\n}\n","$breakpoints: (\n  sm: 600px,\n  md: 900px,\n  lg: 1200px,\n  xl: 1600px\n);\n\n@mixin breakpoint( $breakpoint ) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    @media (max-width: #{ map-get( $breakpoints, $breakpoint ) }) {\n      @content;\n    }\n  } @else if type_of( $breakpoint ) == number and unit( $breakpoint ) == px {\n    @media (max-width: $breakpoint) {\n      @content;\n    }\n  } @else {\n    @warn \"Nie można pobrać żadnej wartości z `#{$breakpoint}`. Nie jest zdefiniowany w mapie `$breakpoints` lub jest nieprawidłową liczbą px.\";\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperComponent": "OffersPeriodic_wrapperComponent__yT74N",
	"header": "OffersPeriodic_header__WESnz",
	"searchFormWrapper": "OffersPeriodic_searchFormWrapper__rhQSB"
};
export default ___CSS_LOADER_EXPORT___;
