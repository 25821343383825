// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddPositionsToCartSelect_confirmationModal__NZLWi {\n  width: 100%;\n  padding: 0 10px;\n}\n.AddPositionsToCartSelect_confirmationModal__NZLWi .AddPositionsToCartSelect_confirmationModalActions__6COWC {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n.AddPositionsToCartSelect_confirmationModal__NZLWi .AddPositionsToCartSelect_confirmationModalActions__6COWC button + button {\n  margin-left: 16px;\n}\n\n.AddPositionsToCartSelect_placeholder__NecjW {\n  display: flex;\n  align-items: center;\n}\n.AddPositionsToCartSelect_placeholder__NecjW > svg {\n  margin-right: 12px;\n  transform: unset;\n}", "",{"version":3,"sources":["webpack://./src/theme/components/containers/AddPositionsToCartSelect/AddPositionsToCartSelect.module.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,eAAA;AAFF;AAIE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AAFJ;AAII;EACE,iBAAA;AAFN;;AAOA;EACE,aAAA;EACA,mBAAA;AAJF;AAME;EACE,kBAAA;EACA,gBAAA;AAJJ","sourcesContent":[".wrapperComponent {\n}\n\n.confirmationModal {\n  width: 100%;\n  padding: 0 10px;\n\n  .confirmationModalActions {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    margin-top: 32px;\n\n    button + button {\n      margin-left: 16px;\n    }\n  }\n}\n\n.placeholder {\n  display: flex;\n  align-items: center;\n\n  > svg {\n    margin-right: 12px;\n    transform: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationModal": "AddPositionsToCartSelect_confirmationModal__NZLWi",
	"confirmationModalActions": "AddPositionsToCartSelect_confirmationModalActions__6COWC",
	"placeholder": "AddPositionsToCartSelect_placeholder__NecjW"
};
export default ___CSS_LOADER_EXPORT___;
