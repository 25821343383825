// główny layout - środkowa belka nagłówka

import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { useDispatch, useSelector, reduxActions } from 'store';
import { Search, CartsButton } from 'components/containers';
import { Container, Link } from 'components/controls';
import { Person } from 'react-bootstrap-icons';

import logo from 'assets/logo.svg';

import styles from 'theme/components/layouts/MainLayout/components/HeaderMain/HeaderMain.module.scss';
import ShoppingListButton from 'components/containers/ShoppingListButton';
import { useLocation } from 'react-router-dom';

const HeaderMain = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.auth);

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Components-Layouts-MainLayout-Components-HeaderMain'
      )}>
      <Container>
        <div className={styles.main}>
          <Link
            to="/"
            onClick={() => dispatch(reduxActions.setSearchKeyword(''))}
            className={styles.logo}>
            <img src={logo} alt="logo" />
          </Link>

          <Search />

          <div className={styles.buttons}>
            {profile?.role !== 'ROLE_OPEN_PROFILE' && (
              <>
                <Link
                  to="/dashboard"
                  className={classnames(styles.link, {
                    [styles.active]: location.pathname.includes('dashboard')
                  })}>
                  <Person /> {t('Twoje konto')}
                </Link>
                <ShoppingListButton />
                <CartsButton />
              </>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeaderMain;
