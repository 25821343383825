// strona główna

import React from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { HtmlBlock } from 'components/containers/HtmlBlock';

import { Container } from 'components/controls';

import styles from 'theme/pages/Home/Home.module.scss';

const Home = () => {
  const { i18n } = useTranslation();
  const supportedLangs = i18n.options.supportedLngs || [];
  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Home')}>
      <Helmet>
        <title>WiseB2B</title>
        <link rel="canonical" href={window.location.href} />
        {supportedLangs.map((language, i) => {
          if (language !== 'cimode')
            return (
              <link
                key={i}
                rel="alternate"
                href={`${window.location.origin}/${language}`}
                hrefLang={language}
              />
            );
        })}
      </Helmet>
      <Container>
        <HtmlBlock sectionId="HOMEPAGE" />
      </Container>
    </div>
  );
};

export default Home;
