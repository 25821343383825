// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormElement_wrapperComponent__v2NNw {\n  width: 100%;\n  margin-bottom: 12px;\n}\n.FormElement_wrapperComponent__v2NNw.FormElement_formElementHalfWidth__49ve3 {\n  width: calc(50% - 10px);\n}\n.FormElement_wrapperComponent__v2NNw label {\n  font-weight: 700;\n  display: block;\n  padding-bottom: 2px;\n}", "",{"version":3,"sources":["webpack://./src/theme/components/controls/FormElement/FormElement.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EAIA,mBAAA;AAFF;AADE;EACE,uBAAA;AAGJ;AAAE;EACE,gBAAA;EACA,cAAA;EACA,mBAAA;AAEJ","sourcesContent":[".wrapperComponent {\n  width: 100%;\n  &.formElementHalfWidth {\n    width: calc(50% - 10px);\n  }\n  margin-bottom: 12px;\n  label {\n    font-weight: 700;\n    display: block;\n    padding-bottom: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperComponent": "FormElement_wrapperComponent__v2NNw",
	"formElementHalfWidth": "FormElement_formElementHalfWidth__49ve3"
};
export default ___CSS_LOADER_EXPORT___;
