// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewVersionPopup_confirmationModal__H-4jd {\n  width: 100%;\n  padding: 0 10px;\n}\n\n.NewVersionPopup_confirmationModalActions__Ntmf9 {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-top: 32px;\n}\n.NewVersionPopup_confirmationModalActions__Ntmf9 button + button {\n  margin-left: 16px;\n}", "",{"version":3,"sources":["webpack://./src/theme/components/containers/NewVersionPopup/NewVersionPopup.module.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,eAAA;AAFF;;AAKA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AAFF;AAIE;EACE,iBAAA;AAFJ","sourcesContent":[".wrapperComponent {\n}\n\n.confirmationModal {\n  width: 100%;\n  padding: 0 10px;\n}\n\n.confirmationModalActions {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-top: 32px;\n\n  button + button {\n    margin-left: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationModal": "NewVersionPopup_confirmationModal__H-4jd",
	"confirmationModalActions": "NewVersionPopup_confirmationModalActions__Ntmf9"
};
export default ___CSS_LOADER_EXPORT___;
