// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArticleForm_inputs__sYzH8 textarea,\n.ArticleForm_inputs__sYzH8 input,\n.ArticleForm_fields__EWZAk textarea,\n.ArticleForm_fields__EWZAk input {\n  width: 100%;\n}\n\n.ArticleForm_inputWrapper__BiwoN {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 20px;\n}\n\n.ArticleForm_dateWrapper__\\+3S6N {\n  display: flex;\n}\n.ArticleForm_dateWrapper__\\+3S6N button {\n  margin-right: 10px;\n}\n\n.ArticleForm_active__rm--T {\n  margin-left: 30px;\n  display: flex;\n  align-items: center;\n}\n\n.ArticleForm_actions__7V7IZ {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 30px;\n}\n\n.ArticleForm_fileWrapper__Hjhlz img {\n  max-height: 150px;\n}", "",{"version":3,"sources":["webpack://./src/theme/pages/Cms/components/ArticleForm.module.scss"],"names":[],"mappings":"AAKE;;;;EAEE,WAAA;AAFJ;;AAMA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAHF;;AAMA;EACE,aAAA;AAHF;AAKE;EACE,kBAAA;AAHJ;;AAOA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;AAJF;;AAOA;EACE,aAAA;EACA,yBAAA;EACA,gBAAA;AAJF;;AAQE;EACE,iBAAA;AALJ","sourcesContent":[".wrapperComponent {}\n\n.inputs,\n.fields {\n\n  textarea,\n  input {\n    width: 100%;\n  }\n}\n\n.inputWrapper {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 20px;\n}\n\n.dateWrapper {\n  display: flex;\n\n  button {\n    margin-right: 10px;\n  }\n}\n\n.active {\n  margin-left: 30px;\n  display: flex;\n  align-items: center;\n}\n\n.actions {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 30px;\n}\n\n.fileWrapper {\n  img {\n    max-height: 150px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputs": "ArticleForm_inputs__sYzH8",
	"fields": "ArticleForm_fields__EWZAk",
	"inputWrapper": "ArticleForm_inputWrapper__BiwoN",
	"dateWrapper": "ArticleForm_dateWrapper__+3S6N",
	"active": "ArticleForm_active__rm--T",
	"actions": "ArticleForm_actions__7V7IZ",
	"fileWrapper": "ArticleForm_fileWrapper__Hjhlz"
};
export default ___CSS_LOADER_EXPORT___;
