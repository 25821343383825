import React from 'react';
import { ArrowRight, ChevronRight } from 'react-bootstrap-icons';
import classnames from 'classnames';
import { Grid } from '@mui/material';
import { Trans } from 'react-i18next';

import { Loader } from 'components/controls';
import ProductItem from 'components/containers/ProductItem';
import { ICategoryListItem, IPaginationResponse, IProductListItem } from 'api/types';

import styles from 'theme/components/containers/Search/DesktopOverlay/DesktopOverlay.module.scss';

type IResponseCategory = IPaginationResponse<ICategoryListItem>;
type IResponseProduct = IPaginationResponse<IProductListItem>;

type Props = {
  setGlobalKeyword: () => void;
  searchKeyword: string;
  isProductsDataLoading: boolean;
  isCategoriesDataLoading: boolean;
  productsData?: IResponseProduct;
  categoriesData?: IResponseCategory;
  setIsFocus: (state: boolean) => void;
  applyProductsParameters: (categoryId?: number) => void;
};

const DesktopOverlay = ({
  setGlobalKeyword,
  searchKeyword,
  applyProductsParameters,
  isProductsDataLoading,
  isCategoriesDataLoading,
  productsData,
  categoriesData,
  setIsFocus
}: Props) => {
  return (
    <div
      className={classnames(
        styles.overlay,
        'StylePath-Components-Containers-Search-DesktopOverlay'
      )}
      onClick={() => {
        setGlobalKeyword();
        setIsFocus(false);
      }}>
      {searchKeyword && (
        <div
          className={styles.searchResults}
          onClick={(event) => {
            event.stopPropagation();
          }}>
          <div className={styles.products}>
            <div className={styles.list}>
              <Grid container>
                {isProductsDataLoading && (
                  <Grid item xs={12}>
                    <Loader />
                  </Grid>
                )}

                {productsData && productsData.items.length === 0 ? (
                  <div className={styles.notFound}>
                    <h3>
                      <Trans>Brak wyników</Trans>
                    </h3>
                    <span>
                      <Trans>Zmień kryteria wyszukiwania</Trans>
                    </span>
                  </div>
                ) : (
                  productsData?.items?.map((product: IProductListItem) => (
                    <Grid item key={product.id}>
                      <ProductItem
                        imageStretch
                        product={product}
                        line
                        searchKeywords={searchKeyword}
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            </div>
            {(productsData?.items || []).length > 0 && (
              <button className={styles.showAllButton} onClick={() => applyProductsParameters()}>
                <Trans>Zobacz wszystkie produkty</Trans> <ArrowRight />
              </button>
            )}
          </div>
          <div className={styles.categories}>
            <div className={styles.title}>
              <Trans>Kategorie</Trans>
            </div>
            <div className={styles.list}>
              {isCategoriesDataLoading && (
                <Grid item xs={12}>
                  <Loader />
                </Grid>
              )}

              {categoriesData?.items?.map((category: ICategoryListItem) => (
                <button
                  onClick={() => applyProductsParameters(category.id)}
                  key={category.id}
                  className={styles.category}>
                  <div>
                    {category.name}
                    <span>({category.products_count})</span>
                  </div>
                  <ChevronRight className={styles.arrow} />
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopOverlay;
