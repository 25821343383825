// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DownloadExport_componentWrapper__iQyub {\n  text-align: center;\n  padding: 50px 0;\n}", "",{"version":3,"sources":["webpack://./src/theme/pages/DownloadExport/DownloadExport.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;AACF","sourcesContent":[".componentWrapper {\n  text-align: center;\n  padding: 50px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"componentWrapper": "DownloadExport_componentWrapper__iQyub"
};
export default ___CSS_LOADER_EXPORT___;
