/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, Dispatch, SetStateAction } from 'react';

import { useCallCommand, useDelete } from 'plugins/api';
import { IDynamicAction } from 'plugins/api/types';
import { loadDataUrlParser } from 'plugins/util/parser';

import 'plugins/theme/components/TablePanel/TablePanel.scss';

// typ danych wejściowych
interface IProps {
  additionalAction: IDynamicAction;
  item: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  triggerDelete: boolean;
  setTriggerDelete: Dispatch<SetStateAction<boolean>>;
}

const AdditionalActionCell: FC<IProps> = ({
  additionalAction,
  item,
  setOpen,
  triggerDelete,
  setTriggerDelete
}) => {
  const endpointUrl = loadDataUrlParser(additionalAction.method_params?.command_url || '', item);

  // wywołanie metody CallCommand
  const { mutate: callCommand } = useCallCommand(endpointUrl || '');

  // wywołanie metody Delete
  const { mutate: deleteAction } = useDelete(endpointUrl || '', {
    onSuccess: () => {
      setTriggerDelete(false);
    },
    onError: () => {
      setTriggerDelete(false);
    }
  });

  useEffect(() => {
    if (triggerDelete) {
      deleteAction({});
    }
  }, [triggerDelete]);

  const handleActionClick = (additionalAction: IDynamicAction) => {
    // obsłużenie akcji CallCommand
    if (additionalAction.method === 'CallCommand') {
      callCommand({});
    }

    // obsłużenie akcji Delete
    if (additionalAction.method === 'Delete') {
      deleteAction({});
      // setOpen(true);
    }
  };

  return <div onClick={() => handleActionClick(additionalAction)}>{additionalAction.label}</div>;
};

export default AdditionalActionCell;
