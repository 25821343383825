/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { ThreeDotsVertical } from 'react-bootstrap-icons';

import { IDynamicAction } from 'plugins/api/types';

import { Modal, Button, DropDown } from 'components/controls';

import { AdditionalActionCell } from './components';

// typ danych wejściowych
interface IProps {
  additionalActions: IDynamicAction[];
  item: any;
}

const AdditionalAction: FC<IProps> = ({ additionalActions, item }) => {
  // czy jest otwarty modal
  const [open, setOpen] = useState(false);

  //trigger dla usuwania
  const [triggerDelete, setTriggerDelete] = useState(false);

  return (
    <>
      <DropDown
        label={
          <div>
            <ThreeDotsVertical />
          </div>
        }
        withDropdownIcon={false}
        items={additionalActions.map((additionalAction) => ({
          label: (
            <AdditionalActionCell
              additionalAction={additionalAction}
              item={item}
              setOpen={setOpen}
              triggerDelete={triggerDelete}
              setTriggerDelete={setTriggerDelete}
            />
          )
        }))}
      />

      {open && (
        <Modal title="asdasdasd" onClose={() => setOpen(false)}>
          {' '}
          <div className="confirmationModalActions">
            <Button onClick={() => setOpen(false)} ghost color="secondary">
              <Trans>Anuluj</Trans>
            </Button>
            <Button onClick={() => setTriggerDelete(true)} color="danger">
              <Trans>Usuń</Trans>
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default AdditionalAction;
