// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NoMatch_wrapperComponent__n5MC7 {\n  padding: 20px 0;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n.NoMatch_wrapperComponent__n5MC7 h1 {\n  font-size: 50px;\n}", "",{"version":3,"sources":["webpack://./src/theme/pages/NoMatch/NoMatch.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;AACF;AACE;EACE,eAAA;AACJ","sourcesContent":[".wrapperComponent {\n  padding: 20px 0;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n\n  h1 {\n    font-size: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperComponent": "NoMatch_wrapperComponent__n5MC7"
};
export default ___CSS_LOADER_EXPORT___;
